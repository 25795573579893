<template>
  <v-card outlined class="mt-n3" tile>
    <v-data-table 
      :headers="computedHeaders" 
      :items="configurationsWithIndex" 
      sort-by="name" 
      class="elevation-1"
      loading-text="Loading... Please wait"
      :server-items-length="organizationApiKeysPagination?.total"
      :options.sync="options"
    >
      <template v-slot:top>
        <v-toolbar flat class="pt-2">
          <div class="mt-5" align="left">
            <v-toolbar-title class="info--text text-uppercase font-weight-bold text--darken-1 justify-center">
              <v-icon class="mr-2 mt-n1" color="info">mdi-book-cog</v-icon>System Configurations
            </v-toolbar-title>

            <v-divider class="mx-4" vertical></v-divider>
          </div>

          <v-spacer></v-spacer>

          <div align="right" class="mx-3 mt-6">
            <v-autocomplete
              :value="computedHeaders"
              :items="headers"
              item-text="text"
              outlined
              dense
              chips
              small-chips
              label="Column Visibility"
              multiple
              return-object
              @change="showHeaders"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 2">
                  <span>{{ item.text }}</span>
                </v-chip>
                <span v-if="index === 2" class="grey--text caption">
                  (+{{ computedHeaders.length - 2 }} others)
                </span>
              </template>
            </v-autocomplete>
          </div>

          <div align="right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="green" dark class="text-capitalize my-auto mx-1" elevation="3" v-bind="attrs" v-on="on" @click="newSystemConfiguration">
                  <v-icon>mdi-bank-plus</v-icon>
                  <span>Add Configuration</span>
                </v-btn>
              </template>
              <span>Add Configuration</span>
            </v-tooltip>
          </div>
        </v-toolbar>
        <v-progress-linear indeterminate color="info" v-if="loading"></v-progress-linear>
      </template>
      
      <template v-slot:no-data>
        <a class="info--text text--darken-1">
          No system configurations found.
          Please contact support for assistance.
        </a>
      </template>

      <template v-slot:item.type="{ item }">
        <v-chip
          :color="getColor(item.type)"
          dark
        >
          {{ getDescription(item.type) }}
        </v-chip>
      </template>

      <template v-slot:item.apikey="{ item }">
        <span class="text-truncate">
          {{ truncateText(10, item.apikey) }}
        </span>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip
          :color="item.status ? 'success' : 'error'"
          dark
          outlined
        >
          {{ item.status ? 'ACTIVE' : 'INACTIVE' }}
        </v-chip>
      </template>

      <template v-slot:[`item.edit`]="{ item }">
        <v-btn color="info" class="mx-1" fab x-small dark elevation="0" @click="showEditConfiguration(item)">
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="showConfigurationModal" max-width="500">
      <v-card>
        <v-card-title class="grey--text text-h5">
          {{ formTitle }} Configuration
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="mb-0 pb-0">
          <v-form class="pt-7 pb-0" ref="form">
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-select 
                  dense 
                  v-model="configuration.type" 
                  :items="configurationTypes" 
                  item-text="label"
                  item-value="value" 
                  label="Type" 
                  :error-messages="apiErrors.type" 
                  outlined
                  :rules="rules.select"
                ></v-select>
              </v-col>

              <v-col cols="12" md="12" sm="12" class="mt-n6">
                <v-text-field 
                  dense 
                  v-model="configuration.username" 
                  :error-messages="apiErrors.username"
                  label="Key" 
                  outlined
                  :rules="rules.required"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" sm="12" class="mt-n6">
                <v-text-field 
                  dense 
                  v-model="configuration.apikey" 
                  :error-messages="apiErrors.apikey"
                  label="Value" 
                  outlined
                  :rules="rules.required"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" sm="12" class="mt-n6">
                <v-text-field 
                  dense 
                  v-model="configuration.sender_id" 
                  :error-messages="apiErrors.sender_id"
                  label="Identifier" 
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" sm="12" class="mt-n6">
                <v-autocomplete 
                  dense 
                  v-model="configuration.organization_id" 
                  :items="organizations" 
                  item-value="id"
                  item-text="legal_name" 
                  label="Organization"
                  :error-messages="apiErrors.organization_id" 
                  outlined
                  :rules="rules.select"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="12" sm="12" class="mt-n6">
                <v-select 
                  dense 
                  v-model="configuration.status" 
                  :items="configurationStatus" 
                  item-text="label"
                  item-value="value" 
                  label="Status" 
                  :error-messages="apiErrors.status" 
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="red darken-1" text @click="closeModal" class="text-capitalize mx-1">
            Cancel
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn 
            v-if="editConfiguration" 
            color="green darken-1 white--text" 
            class="text-capitalize mx-1"
            :loading="btn_loading"
            :disabled="btn_loading"
            @click="updateConfiguration"
          >
            Update
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>

          <v-btn 
            v-else 
            color="green darken-1 white--text"
            class="text-capitalize mx-1"
            :loading="btn_loading"
            :disabled="btn_loading"
            @click="saveConfiguration"
          >
            Save
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import SettingsService from "../../services/settings.service";
import OrganizationService from "../../services/organization.service";
import Configuration from "../../models/configuration";

export default {
  data: () => ({
    name: "SystemConfigurations",
    loading: true,
    items: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      attachment: "",
      attachment_file: [],
    },
    defaultItem: {
      name: "",
      attachment: "",
      attachment_file: [],
    },
    remember: 0,
    timeout: 2000,
    dialog: false,
    valid: false,
    loader: null,
    btn_loading: false,
    options: {},
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 1) || "Min 1 character",
      ],
      file: [
        (v) => !!v || "File is required",
        (v) => (v && v.length > 0) || "File is required",
      ],
    },
    search: '',
    selectedHeaders: [],
    headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "index",
        visible: true,
        position: 0,
      },
      {
        text: "Type",
        value: "type",
        visible: true,
        position: 1,
      },
      {
        text: "Key",
        value: "username",
        visible: true,
        position: 2,
      },
      {
        text: "Value",
        value: "apikey",
        visible: true,
        position: 3,
      },
      {
        text: "Identifier",
        value: "sender_id",
        visible: true,
        position: 4,
      },
      {
        text: "Organization",
        value: "organization_name",
        visible: false,
        position: 5,
      },
      {
        text: "Status",
        value: "status",
        visible: true,
        position: 6,
      },
      {
        text: "Created By",
        value: "user_name",
        visible: false,
        position: 7,
      },
      {
        text: "Edit",
        align: "center",
        value: "edit",
        sortable: false,
        visible: true,
        position: 8,
      },
    ],
    configuration: new Configuration(),
    showConfigurationModal: false,
    apiErrors: [],
    editConfiguration: false,
    organizationApiKeysList: [],
    closeOnContentClick: true,
    currentStep: 1,
    organizationApiKeysPagination: {},
    configurationStatus: [
      {
        id: 1,
        label: 'Active',
        value: 1,
      },
      {
        id: 2,
        label: 'Inactive',
        value: 0,
      },
    ],
    configurationTypes: [
      {
        type: 1,
        label: 'SMS',
        key: 1,
        value: 1,
        color: 'info'
      },
      {
        type: 2,
        label: 'VM Credentials',
        key: 2,
        value: 2,
        color: 'teal'
      },
      {
        type: 3,
        label: 'Member Invite Rules',
        key: 3,
        value: 3,
        color: 'warning'
      },
    ],
    organizations: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getLoading() {
      return this.loading;
    },
    org() {
      return JSON.parse(localStorage.getItem("org"));
    },
    configurationsWithIndex() {
      return this.organizationApiKeysList.map(
        (items, index) => ({
          ...items,
          index: index + 1,
        })
      )
    },
    computedHeaders() {
      return this.selectedHeaders.filter((item) => item.visible == true);
    },
  },

  created() {
    this.selectedHeaders = this.headers;
    this.getOrganizations();
  },

  watch: {
    options: {
      handler () {
        this.getOrganizationApiKeys();
      },
      deep: true,
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },

  methods: {
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },

    showHeaders(headers) {
      this.selectedHeaders.forEach((header) => {
        let index = -1;
        index = headers.findIndex((item) => item.value == header.value);
        if (index > -1 && this.selectedHeaders[index].visible == false) {
          this.selectedHeaders[index].visible = true;
        }
      });
    },

    sortDataBy(data, byKey) {
      let sortedData = [];
      sortedData = data.sort(function(a, b) {
        return a[byKey] - b[byKey];
      });

      return sortedData;
    },

    getColor(type) {
      let index = -1;
      let color = 'primary';
      index = this.configurationTypes.findIndex((item) => item.type == type);
      if (index > -1) {
        color = this.configurationTypes[index]?.color;
      }

      return color;
    },

    getDescription(type) {
      let index = -1;
      let label = 'Undefined';
      index = this.configurationTypes.findIndex((item) => item.type == type);
      if (index > -1) {
        label = this.configurationTypes[index]?.label;
      }

      return label?.toUpperCase();
    },

    truncateText(length, text) {
      if (typeof text != 'undefined') {
        var shortText = (text.length > length) ? text.slice(0, length - 1) + '\u2026' : text;
        return shortText;
      }
    },

    validate() {
      return this.$refs?.form?.validate();
    },

    reset() {
      return this.$refs?.form?.reset();
    },

    resetValidation() {
      return this.$refs?.form?.resetValidation();
    },

    getOrganizations() {
      let user = JSON.parse(window.localStorage.getItem('user'));

      OrganizationService.personOrganizations(user.id).then(
        (response) => {
          if (response.status == 200) {
            this.organizations = response.data.data;
            this.loading = false;
          }
        },
        (error) => {
          this.organizations = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },

    getOrganizationApiKeys() {
      this.loading = true;
      SettingsService.index(this.options).then(
        (response) => {
          if (response.status === 200) {
            this.organizationApiKeysList = response?.data?.data?.data;
            this.organizationApiKeysPagination = Object.assign({}, response?.data?.data);
            this.loading = false;
          } else {
            this.organizationApiKeysList = [];
            this.organizationApiKeysPagination = Object.assign({}, {});
          }
        },
        (error) => {
          this.organizationApiKeysList = [];
          console.log(error);
          if (error.response.status === 401) {
            this.handleLogout();
          }
        }
      );
    },

    showEditConfiguration(item) {
      this.configuration.id = item.id;
      this.configuration.organization_id = item.organization_id;
      this.configuration.username = item.username;
      this.configuration.apikey = item.apikey;
      this.configuration.sender_id = item.sender_id;
      this.configuration.type = item.type;
      this.configuration.status = item.status;
      
      if (this.configuration.id) {
        this.editedIndex = this.configuration.id;
        this.editConfiguration = true;
        this.showConfigurationModal = true;
      }
    },

    newSystemConfiguration() {
      this.reset();
      this.resetValidation();
      this.showConfigurationModal = true;
      this.editConfiguration = false;
      this.editedItem = -1;
      this.configuration = new Configuration();
    },

    closeModal() {
      this.showConfigurationModal = false;
      this.editConfiguration = false;
      this.editedItem = -1;
      this.configuration = new Configuration();
    },

    saveConfiguration() {
      this.valid = this.validate();
      if (this.valid) {
        this.loader = 'btn_loading';
        SettingsService.store(this.configuration).then(
          (response) => {
            if (response.status === 200) {
              this.organizationApiKeysList.push(this.editedItem);
              this.$store.dispatch("alert/success", "System configuration created successfully");
              this.loading = false;
              this.getOrganizationApiKeys();
              this.closeModal();
            }
          },
          (error) => {
            if (error.response.status === 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
              this.apiErrors = error.response.data.errors;
              this.loading = false;
              this.getOrganizationApiKeys();
            }

            if (error.response.status === 401) {
              this.handleLogout();
              this.loading = false;
            }

            if (error.response.status === 404) {
              this.$store.dispatch("alert/error", error.response.data.message);
              this.closeModal();
            }
          }
        );
      }
    },

    updateConfiguration() {
      this.valid = this.validate();
      if (this.valid) {
        this.loader = 'btn_loading';
        SettingsService.update(this.configuration).then(
          (response) => {
            if (response.status === 200) {
              this.$store.dispatch("alert/success", "System Configuration updated successfully");
              this.loading = false;
              this.getOrganizationApiKeys();
              this.closeModal();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
              this.getOrganizationApiKeys();
              this.loading = false;
            }
          },
          (error) => {
            if (error.response.status === 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
              this.getOrganizationApiKeys();
            }

            if (error.response.status === 401) {
              this.handleLogout();
              this.loading = false;
            }
            
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },
  },

  mounted() {
    // Set the nearest positioned ancestor to the body element
    document.body.style.position = 'relative';
  }
};
</script>

<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
