import Axios from './Axios';

class SettingsService {
  index(params) {
    let perPage = params?.itemsPerPage ?? 20;
    let page = params?.page ?? 1;

    return Axios({
      method: "GET",
      url: '/organization-api-keys?page=' + page + '&per_page=' + perPage
    });
  }

  store(item) {
    return Axios({
      method: "POST",
      url: '/organization-api-keys',
      data: item
    })
  }

  update(item) {
    return Axios({
      method: "PATCH",
      url: '/organization-api-keys/' + item.id,
      data: item
    })
  }
}

export default new SettingsService();
