// Configuration model 
export default class Configuration {
  constructor(id, organization_id, username, apikey, sender_id, type, status) {
    this.id = id;
    this.organization_id = organization_id;
    this.username = username;
    this.apikey = apikey;
    this.sender_id = sender_id;
    this.type = type;
    this.status = status;
  }
}