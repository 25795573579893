<template>
  <div>
    <v-data-table 
      :headers="headers" 
      :items="smsData" 
      @row-click="viewDetails"
      :server-items-length="smsDataPagination?.total"
      :options.sync="options"
      loading-text="Loading... Please wait"
    >
    <template v-slot:top>
        <v-toolbar flat class="pt-2">
          <div class="mt-5" align="left">
            <v-toolbar-title class="info--text text-uppercase font-weight-bold text--darken-1 justify-center">
              <v-icon class="mr-2 mt-n1" color="info">mdi-message-processing</v-icon>SMS Logs
            </v-toolbar-title>

            <v-divider class="mx-4" vertical></v-divider>
          </div>

          <v-spacer></v-spacer>
        </v-toolbar>
        <v-progress-linear indeterminate color="info" v-if="loading"></v-progress-linear>
      </template>
      
      <template v-slot:no-data>
        <a class="info--text text--darken-1">
          No SMS Logs Found.
          Please contact support for assistance.
        </a>
      </template>

      <template v-slot:item.sent_date="{ item }">
        {{ formatDate(item.sent_date) }}
      </template>

      <template v-slot:item.message="{ item }">
        <div>
          <span v-if="!showFullMessage">{{ formatMessage(item.message) }}</span>
          <span v-if="showFullMessage">{{ item.message }}</span>
          <v-btn small text @click="viewFullMessage(item)">{{ showFullMessage ? 'Show Less' : 'View More' }}</v-btn>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-if="selectedSms" v-model="detailsDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">{{ selectedSms.message }}</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="(value, key) in selectedSmsDetails" :key="key">
              <v-list-item-content>
                <v-list-item-title>{{ key }}</v-list-item-title>
                <v-list-item-subtitle>{{ value }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="detailsDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import ReportsService from "@/services/reports.service";

export default {
  name: "SmsLogs",
  data() {
    return {
      headers: [
        { text: "SMS ID", value: "sms_id" },
        { text: "Message", value: "message" },
        { text: "Phone", value: "phone" },
        { text: "Sent Date", value: "sent_date" },
        { text: "User Name", value: "user_name" },
      ],
      smsData: [],
      selectedSms: null,
      selectedSmsDetails: {},
      detailsDialog: false,
      loading: false,
      showFullMessage: false,
      smsDataPagination: {},
      options: {},
    };
  },
  methods: {
    viewDetails(item) {
      this.selectedSms = item;
      this.selectedSmsDetails = {
        "SMS ID": item.sms_id,
        Phone: item.phone,
        "Sent Date": this.formatDate(item.sent_date),
        "User Name": item.user_name,
      };
      this.detailsDialog = true;
    },
    getSmsLogs() {
      const org_id = 1;
      this.loading = true;
      ReportsService.smsLogs(org_id, { options: this.options })
        .then((response) => {
          if (response.status === 200) {
            this.smsData = response.data?.data?.data;
            this.smsDataPagination = response?.data?.data;
          }
        })
        .catch((error) => {
          this.$store.dispatch("alert/error", error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
    formatMessage(message) {
      if (message.length > 70) {
        return message.substring(0, 100) + "...";
      } else {
        return message;
      }
    },
    viewFullMessage(item) {
      this.detailsDialog = true;
      this.selectedSms = item;
    },
  },
  watch: {
    options: {
      handler () {
        this.getSmsLogs();
      },
      deep: true,
    },
  },
};
</script>
